<template>
	<div>
		<StandardMindmap v-if="map_id" :prop_map_id="map_id"></StandardMindmap>

		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.gather_info.title"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import { generateRandomString } from "@/utils/helpers";
import StandardMindmap from "@/components/shared/tools/standardMindmap/Index.vue";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";

export default {
	name: "cc3hhl_StandardMapPage",

	data() {
		return {
			map_id: null,
			dialog: false,
		};
	},

	components: {
		StandardMindmap,
		StageFinishDialog,
	},

	created() {
		this.readyToListenEvents();
	},

	mounted() {
		this.getMap();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("redirect-from-cc3hhl-standard-map", () => {
				this.dialog = true;
			});
		},

		async getMap() {
			try {
				this.$loader.start();

				let response = await this.$store.dispatch("mindmap/list", {
					category: this.$defines.SYSTEM_CATEGORY,
				});
				if (response && response.data && response.data.length) {
					this.map_id = response.data[0].id;
				} else {
					await this.createMap();
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async createMap() {
			let response = await this.$store.dispatch("mindmap/create", {
				data: {
					linkData: {},
					nodeData: {
						id: generateRandomString(),
						root: true,
						topic: this.$t("app.root_node"),
					},
				},
			});
			if (response) {
				this.map_id = response.id;
			} else {
				throw new Error("app.ntfy.err.generic", {
					cause: "werCustom",
				});
			}
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */
		this.$eventBus.$off("redirect-from-cc3hhl-standard-mapp");
	},
};
</script>

<style></style>
